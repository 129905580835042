.projects {
    background-color: #F2F2F2;
    margin-top: 20vmin;
}
.projects h2 {
    padding-top: 5vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1vh;
}
.projects .slideRight {
    animation: 0.5s slideRight;
}
@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
.projects-bar {
    height: 2px;
    width: 5vw;
    background-color: #034A92;
    margin: auto;
    margin-bottom: 8vh;
}
.projects-grid {
    width: 1068px;
    /* width: 712px; */
    margin: auto;
}
.card-1, .card-2, .card-3, .card-4 {
    width: 356px;
    height: 353px;
    position: relative;
}
.overlay-1, .overlay-2, .overlay-3, .overlay-4 {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.5s ease;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
}
.card-1:hover .overlay-1, .card-2:hover .overlay-2, .card-3:hover .overlay-3, .card-4:hover .overlay-4 {
    opacity: 1;
}
.projects img {
    width: 356px;
    height: 353px;
    object-fit: cover;
}
.projects-grid button {
    width: 8em;
    height: 2em;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-color: #F2F2F2;
    border: 2px solid #034A92;
    color: #034A92;
    transition-duration: 0.4s;
    margin-top: 5%;
}
.projects-grid button:hover {
    background-color: #034A92;
    color: white;
}
.projects-grid button:focus {
    outline: none;
}
.projects-grid p {
    color: #034A92;
    font-size: 14px;
    margin-top: 0;
}
.projects-grid h3 {
    margin-bottom: 3px;
    font-size: 18px;
    margin-top: 20%;
}
.github {
    font-size: calc(5px + 2vmin);
    width: 14vmin;
    height: 4vmin;
    text-align: center;
    cursor: pointer;
    background-color: #F2F2F2;
    border: 2px solid #034A92;
    color: #034A92;
    padding: 0 5px 0 5px;
    transition-duration: 0.4s;
    margin-top: 8vh;
}
.github:hover {
    background-color: #034A92;
    color: white;
}
.projects button:focus {
    outline: none;
}
.dialog img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    overflow: hidden;
}
.dialog-line1 {
    background-color: #034A92;
    width: 100%;
    height: 3px;
}
.dialog-line2 {
    background-color: #034A92;
    width: 100%;
    height: 1px;
}
.dialog h2 {
    color: black;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    margin-top: 5%;
}
.dialog h3 {
    margin-top: 0;
    font-size: 14px;
    font-weight: normal;
    color: gray;
}
.dialog p {
    font-size: 14px;
    color: black;
    margin-bottom: 15%;
    margin-top: 5%;
}
.dialog-buttons {
    width: 90%;
    margin: auto;
    text-align: left;
    margin-bottom: 2vh;
}
.dialog-buttons button {
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border: 2px solid #034A92;
    color: #034A92;
    padding: 0 5px 0 5px;
    transition-duration: 0.4s;
}
.dialog-buttons button:hover {
    background-color: #034A92;
    color: white;
}
.dialog-buttons button:focus {
    outline: none;
}
.dialog-button1 {
    width: 120px;
    height: 30px;
}
.dialog-button2 {
    width: 100px;
    height: 30px;
    margin-left: 2%;
}
@media screen and (max-width: 600px) {
    .projects-bar {
        margin-bottom: 5vh;
    }
    .projects-grid {
        width: 300px;
    }
    .card-1, .card-2, .card-3, .card-4 {
        width: 256px;
        height: 253px;
    }
    .projects img {
        width: 256px;
        height: 253px;
    }
    .github {
        margin: auto;
        margin-top: 5vh;
        width: 18vmin;
        height: 6vmin;
        font-size: 14px;
    }
    .dialog img {
        height: 200px;
    }
    .dialog h2 {
        font-size: 16px;
    }
    .dialog h3 {
        font-size: 12px;
    }
    .dialog p {
        font-size: 12px;
    }
    .dialog-buttons button {
        font-size: 14px;
    }
    .dialog-button1 {
        width: 110px;
        height: 25px;
    }
    .dialog-button2 {
        width: 90px;
        height: 25px;
        margin-left: 2%;
    }
}
.projects svg {
    position: relative;
    top: 1vh;
}