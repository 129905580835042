.facts h2 {
    padding-top: 5vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1vh;
}
.facts .slideLeft {
    animation: 0.5s slideLeft;
}
@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
.facts-bar {
    height: 2px;
    width: 8vw;
    background-color: #034A92;
    margin: auto;
    margin-bottom: 8vh;
}
.facts-container {
    margin: auto;
    margin-top: 8vh;
    margin-bottom: 8vh;
}
.facts-content {
    display: inline-block;
    text-align: left;
    font-size: calc(7px + 1vmin);
}   
.photos-grid {
    margin: auto;
    width: 1100px;
    font-size: 0;
}
.photo-1, .photo-2, .photo-3, .photo-4, .photo-5, .photo-6 {
    width: calc(1100px / 3);
    height: 350px;
}
.photo-1 {
    background-image: url('../images/facts-images/camping.png');
    background-size: cover;
    background-position: center bottom;
}
.photo-2 {
    background-image: url('../images/GrindOnTheGrid1.jpg');
    background-size: cover;
    background-position: center bottom;
}
.photo-3 {
    background-image: url('../images/GrindOnTheGrid2.jpg');
    background-size: cover;
    background-position: 65% bottom;
}
.photo-4 {
    background-image: url('../images/facts-images/50k.jpg');
    background-size: cover;
}
.photo-5 {
    background-image: url('../images/facts-images/biking.jpg');
    background-size: cover;
    background-position: right center;
}
.photo-6 {
    background-image: url('../images/mtemmons.jpg');
    background-size: cover;
}
@media screen and (max-width: 600px) {
    .facts-bar {
        margin-bottom: 5vh;
    }
    .facts p {
        text-align: center;
        width: 70%;
        font-size: 14px;
        line-height: 3vmax;
        margin: auto;
        margin-bottom: 3vmax;
    }
    .facts-container {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    .photos-grid {
        width: 100%;
    }
    .photo-1, .photo-2, .photo-3, .photo-4, .photo-5, .photo-6 {
        width: 50%;
        height: 250px;
    }
    .photo-1 {
        background-position: center left;
    }
    .photo-2 {
        background-position: 30% center;
    }
    .photo-5 {
        background-position: 75% center;
    }
}