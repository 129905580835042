.about h2 {
    margin-top: 5vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1vh;
}
.about .slideLeft {
    animation: 0.5s slideLeft;
}
@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
.about-bar {
    height: 2px;
    width: 5vw;
    background-color: #034A92;
    margin: auto;
    margin-bottom: 8vh;
}
.about img {
    width: 15vmax;
    margin-left: 25vmax;
}
.about p {
    text-align: left;
    margin-left: 3vmax;
    margin-top: 0;
    font-size: calc(8px + 1vmin);
    line-height: calc(5px + 1.5vmax);
    width: 25vmax;
}
.about button {
    font-size: calc(5px + 2vmin);
    width: 14vmin;
    height: 4vmin;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border: 2px solid #034A92;
    color: #034A92;
    padding: 0 5px 0 5px;
    margin-top: 8vh;
    margin-right: 50%;
    transition-duration: 0.4s;
}
.about button:hover {
    background-color: #034A92;
    color: white;
}
.about button:focus {
    outline: none;
}
.about a {
    text-decoration: underline;
    color: #41B9FF;
}
.about a:visited {
    text-decoration: none;
    color: #41B9FF;
}
@media screen and (max-width: 600px) {
    .about img {
        margin: auto;
        width: 20vmax;
    }
    .about-bar {
        margin-bottom: 5vh;
    }
    .about p {
        text-align: center;
        width: 70%;
        font-size: 14px;
        line-height: 3vmax;
        margin: auto;
        margin-top: 5vh;
    }
    .about button {
        margin: auto;
        margin-top: 5vh;
        width: 18vmin;
        height: 6vmin;
        font-size: 14px;
    }
}
