.Header {
    height: 100vh;
    display: flex;
    align-items: flex-end;
    background-color: #232323;
}
.Header h1 {
    color: white;
    font-weight: normal;
    font-size: calc(15px + 4vmin);
    animation: 1s slideInLate;
}
.Header button {
    font-size: calc(10px + 2vmin);
    cursor: pointer;
    background-color: #232323;
    border: 2px solid #41B9FF;
    color: #41B9FF;
    transition-duration: 0.4s;
    padding: 3px 15px;
    animation: 1s slideIn;

}
.contact-button {
    margin-left: 3%;
}
.Header a {
    color: #41B9FF;
}
/* .Header a:hover {
    color: white;
} */
@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideInLate {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    60% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.Header svg {
    margin-left: 6px;
    transition-duration: 0.4s;
    width: 3vmin;
    height: 2.5vh;
    position: relative;
    top: 0.5vmin;
}
.Header button:hover {
    background-color: #41B9FF;
    color: white;
}
.Header button:hover svg {
    transform: rotate(90deg);
}
.Header button:focus {
    outline: none;
}
.col-1 {
    width: 54%;
    margin-bottom: 26%;
}
.col-2 {
    width: 46%;
}
.Header img {
    width: 90%;
}
@media screen and (max-width: 1024px) {
    .Header {
        display: inline;
    }
    .col-1, .col-2 {
        width: 100%;
        background-color: #232323;
    }
    .col-1 {
        margin-bottom: 0;
    }
    .Header h1 {
        margin-top: 0;
        padding-top: 10%;
    }
}

