.contact {
    background-color: #232323;
    margin-top: 20vmin;
    padding-bottom: 5vh;
}
.contact-grid {
    margin: auto;
    width: 700px;
    margin-top: 4vh;
}
.contact h2 {
    font-size: calc(5px + 2vmin);
    color: white;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0;
}
.contact h3 {
    font-size: 1.8vmin;
    color: #A4A4A4;
    font-weight: normal;
    text-align: left;
    margin-top: 0;
}
.contact-button-container {
    text-align: left;
}
.contact button {
    font-size: 1.6vmin;
    width: 12vmin;
    height: 3.5vmin;
    text-align: center;
    cursor: pointer;
    background-color: #232323;
    border: 1px solid #41B9FF;
    color: #41B9FF;
    padding: 0 5px 0 5px;
    margin-top: 2vh;
    transition-duration: 0.4s;
}
.contact button:hover {
    background-color: #41B9FF;
    color: white;
    border: solid 1px #41B9FF;
}
.contact button:focus {
    outline: none;
}
.icon-1 img, .icon-2 img, .icon-4 img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.github-icon {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 3px;
    display: inline-block;
}
.icon-3 img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 8%;
    margin-top: 7%;
}
.icons-container {
    text-align: right;
}
.icons {
    text-align: left;
    display: inline-block;
}
.icon-1, .icon-2, .icon-3, .icon-4 {
    margin-top: 1vmin;
}
.contact p {
    font-size: 14px;
    display: inline;
    color: #A4A4A4;
    margin-left: 3vmin;
    position: relative;
    bottom: 1vh;
    cursor: pointer;
    transition-duration: 0.4s;
}
.contact p:hover {
    font-weight: bold;
}
.resume {
    width: 8%;
}
@media screen and (max-width: 600px) {
    .contact-grid {
        width: 250px;
    }
    .icon-1 img, .icon-2 img, .icon-4 img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .github-icon {
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 3px;
        display: inline-block;
    }
    .icon-3 img {
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-left: 8%;
        margin-top: 7%;
    }
    .contact p {
        font-size: 11px;
    }
    .items-container {
        text-align: center;
    }
    .contact h2 {
        text-align: center;
        font-size: 18px;
    }
    .contact h3 {
        text-align: center;
        font-size: 14px;
    }
    .contact-button-container {
        text-align: center;
        margin-bottom: 5vh;
    }
    .contact button {
        font-size: 12px;
        width: 75px;
        height: 20px;
    }
}