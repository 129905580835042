@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.Header {
    height: 100vh;
    display: flex;
    align-items: flex-end;
    background-color: #232323;
}
.Header h1 {
    color: white;
    font-weight: normal;
    font-size: calc(15px + 4vmin);
    -webkit-animation: 1s slideInLate;
            animation: 1s slideInLate;
}
.Header button {
    font-size: calc(10px + 2vmin);
    cursor: pointer;
    background-color: #232323;
    border: 2px solid #41B9FF;
    color: #41B9FF;
    transition-duration: 0.4s;
    padding: 3px 15px;
    -webkit-animation: 1s slideIn;
            animation: 1s slideIn;

}
.contact-button {
    margin-left: 3%;
}
.Header a {
    color: #41B9FF;
}
/* .Header a:hover {
    color: white;
} */
@-webkit-keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes slideInLate {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    60% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideInLate {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    60% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.Header svg {
    margin-left: 6px;
    transition-duration: 0.4s;
    width: 3vmin;
    height: 2.5vh;
    position: relative;
    top: 0.5vmin;
}
.Header button:hover {
    background-color: #41B9FF;
    color: white;
}
.Header button:hover svg {
    transform: rotate(90deg);
}
.Header button:focus {
    outline: none;
}
.col-1 {
    width: 54%;
    margin-bottom: 26%;
}
.col-2 {
    width: 46%;
}
.Header img {
    width: 90%;
}
@media screen and (max-width: 1024px) {
    .Header {
        display: inline;
    }
    .col-1, .col-2 {
        width: 100%;
        background-color: #232323;
    }
    .col-1 {
        margin-bottom: 0;
    }
    .Header h1 {
        margin-top: 0;
        padding-top: 10%;
    }
}


.about h2 {
    margin-top: 5vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1vh;
}
.about .slideLeft {
    -webkit-animation: 0.5s slideLeft;
            animation: 0.5s slideLeft;
}
@-webkit-keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
.about-bar {
    height: 2px;
    width: 5vw;
    background-color: #034A92;
    margin: auto;
    margin-bottom: 8vh;
}
.about img {
    width: 15vmax;
    margin-left: 25vmax;
}
.about p {
    text-align: left;
    margin-left: 3vmax;
    margin-top: 0;
    font-size: calc(8px + 1vmin);
    line-height: calc(5px + 1.5vmax);
    width: 25vmax;
}
.about button {
    font-size: calc(5px + 2vmin);
    width: 14vmin;
    height: 4vmin;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border: 2px solid #034A92;
    color: #034A92;
    padding: 0 5px 0 5px;
    margin-top: 8vh;
    margin-right: 50%;
    transition-duration: 0.4s;
}
.about button:hover {
    background-color: #034A92;
    color: white;
}
.about button:focus {
    outline: none;
}
.about a {
    text-decoration: underline;
    color: #41B9FF;
}
.about a:visited {
    text-decoration: none;
    color: #41B9FF;
}
@media screen and (max-width: 600px) {
    .about img {
        margin: auto;
        width: 20vmax;
    }
    .about-bar {
        margin-bottom: 5vh;
    }
    .about p {
        text-align: center;
        width: 70%;
        font-size: 14px;
        line-height: 3vmax;
        margin: auto;
        margin-top: 5vh;
    }
    .about button {
        margin: auto;
        margin-top: 5vh;
        width: 18vmin;
        height: 6vmin;
        font-size: 14px;
    }
}

.projects {
    background-color: #F2F2F2;
    margin-top: 20vmin;
}
.projects h2 {
    padding-top: 5vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1vh;
}
.projects .slideRight {
    -webkit-animation: 0.5s slideRight;
            animation: 0.5s slideRight;
}
@-webkit-keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
.projects-bar {
    height: 2px;
    width: 5vw;
    background-color: #034A92;
    margin: auto;
    margin-bottom: 8vh;
}
.projects-grid {
    width: 1068px;
    /* width: 712px; */
    margin: auto;
}
.card-1, .card-2, .card-3, .card-4 {
    width: 356px;
    height: 353px;
    position: relative;
}
.overlay-1, .overlay-2, .overlay-3, .overlay-4 {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.5s ease;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
}
.card-1:hover .overlay-1, .card-2:hover .overlay-2, .card-3:hover .overlay-3, .card-4:hover .overlay-4 {
    opacity: 1;
}
.projects img {
    width: 356px;
    height: 353px;
    object-fit: cover;
}
.projects-grid button {
    width: 8em;
    height: 2em;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-color: #F2F2F2;
    border: 2px solid #034A92;
    color: #034A92;
    transition-duration: 0.4s;
    margin-top: 5%;
}
.projects-grid button:hover {
    background-color: #034A92;
    color: white;
}
.projects-grid button:focus {
    outline: none;
}
.projects-grid p {
    color: #034A92;
    font-size: 14px;
    margin-top: 0;
}
.projects-grid h3 {
    margin-bottom: 3px;
    font-size: 18px;
    margin-top: 20%;
}
.github {
    font-size: calc(5px + 2vmin);
    width: 14vmin;
    height: 4vmin;
    text-align: center;
    cursor: pointer;
    background-color: #F2F2F2;
    border: 2px solid #034A92;
    color: #034A92;
    padding: 0 5px 0 5px;
    transition-duration: 0.4s;
    margin-top: 8vh;
}
.github:hover {
    background-color: #034A92;
    color: white;
}
.projects button:focus {
    outline: none;
}
.dialog img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    overflow: hidden;
}
.dialog-line1 {
    background-color: #034A92;
    width: 100%;
    height: 3px;
}
.dialog-line2 {
    background-color: #034A92;
    width: 100%;
    height: 1px;
}
.dialog h2 {
    color: black;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    margin-top: 5%;
}
.dialog h3 {
    margin-top: 0;
    font-size: 14px;
    font-weight: normal;
    color: gray;
}
.dialog p {
    font-size: 14px;
    color: black;
    margin-bottom: 15%;
    margin-top: 5%;
}
.dialog-buttons {
    width: 90%;
    margin: auto;
    text-align: left;
    margin-bottom: 2vh;
}
.dialog-buttons button {
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border: 2px solid #034A92;
    color: #034A92;
    padding: 0 5px 0 5px;
    transition-duration: 0.4s;
}
.dialog-buttons button:hover {
    background-color: #034A92;
    color: white;
}
.dialog-buttons button:focus {
    outline: none;
}
.dialog-button1 {
    width: 120px;
    height: 30px;
}
.dialog-button2 {
    width: 100px;
    height: 30px;
    margin-left: 2%;
}
@media screen and (max-width: 600px) {
    .projects-bar {
        margin-bottom: 5vh;
    }
    .projects-grid {
        width: 300px;
    }
    .card-1, .card-2, .card-3, .card-4 {
        width: 256px;
        height: 253px;
    }
    .projects img {
        width: 256px;
        height: 253px;
    }
    .github {
        margin: auto;
        margin-top: 5vh;
        width: 18vmin;
        height: 6vmin;
        font-size: 14px;
    }
    .dialog img {
        height: 200px;
    }
    .dialog h2 {
        font-size: 16px;
    }
    .dialog h3 {
        font-size: 12px;
    }
    .dialog p {
        font-size: 12px;
    }
    .dialog-buttons button {
        font-size: 14px;
    }
    .dialog-button1 {
        width: 110px;
        height: 25px;
    }
    .dialog-button2 {
        width: 90px;
        height: 25px;
        margin-left: 2%;
    }
}
.projects svg {
    position: relative;
    top: 1vh;
}
.facts h2 {
    padding-top: 5vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1vh;
}
.facts .slideLeft {
    -webkit-animation: 0.5s slideLeft;
            animation: 0.5s slideLeft;
}
@-webkit-keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
.facts-bar {
    height: 2px;
    width: 8vw;
    background-color: #034A92;
    margin: auto;
    margin-bottom: 8vh;
}
.facts-container {
    margin: auto;
    margin-top: 8vh;
    margin-bottom: 8vh;
}
.facts-content {
    display: inline-block;
    text-align: left;
    font-size: calc(7px + 1vmin);
}   
.photos-grid {
    margin: auto;
    width: 1100px;
    font-size: 0;
}
.photo-1, .photo-2, .photo-3, .photo-4, .photo-5, .photo-6 {
    width: calc(1100px / 3);
    height: 350px;
}
.photo-1 {
    background-image: url(/static/media/camping.7edff3ed.png);
    background-size: cover;
    background-position: center bottom;
}
.photo-2 {
    background-image: url(/static/media/GrindOnTheGrid1.348467e1.jpg);
    background-size: cover;
    background-position: center bottom;
}
.photo-3 {
    background-image: url(/static/media/GrindOnTheGrid2.9f750e1a.jpg);
    background-size: cover;
    background-position: 65% bottom;
}
.photo-4 {
    background-image: url(/static/media/50k.b2a55eaf.jpg);
    background-size: cover;
}
.photo-5 {
    background-image: url(/static/media/biking.03469290.jpg);
    background-size: cover;
    background-position: right center;
}
.photo-6 {
    background-image: url(/static/media/mtemmons.0ee30550.jpg);
    background-size: cover;
}
@media screen and (max-width: 600px) {
    .facts-bar {
        margin-bottom: 5vh;
    }
    .facts p {
        text-align: center;
        width: 70%;
        font-size: 14px;
        line-height: 3vmax;
        margin: auto;
        margin-bottom: 3vmax;
    }
    .facts-container {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    .photos-grid {
        width: 100%;
    }
    .photo-1, .photo-2, .photo-3, .photo-4, .photo-5, .photo-6 {
        width: 50%;
        height: 250px;
    }
    .photo-1 {
        background-position: center left;
    }
    .photo-2 {
        background-position: 30% center;
    }
    .photo-5 {
        background-position: 75% center;
    }
}
.contact {
    background-color: #232323;
    margin-top: 20vmin;
    padding-bottom: 5vh;
}
.contact-grid {
    margin: auto;
    width: 700px;
    margin-top: 4vh;
}
.contact h2 {
    font-size: calc(5px + 2vmin);
    color: white;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0;
}
.contact h3 {
    font-size: 1.8vmin;
    color: #A4A4A4;
    font-weight: normal;
    text-align: left;
    margin-top: 0;
}
.contact-button-container {
    text-align: left;
}
.contact button {
    font-size: 1.6vmin;
    width: 12vmin;
    height: 3.5vmin;
    text-align: center;
    cursor: pointer;
    background-color: #232323;
    border: 1px solid #41B9FF;
    color: #41B9FF;
    padding: 0 5px 0 5px;
    margin-top: 2vh;
    transition-duration: 0.4s;
}
.contact button:hover {
    background-color: #41B9FF;
    color: white;
    border: solid 1px #41B9FF;
}
.contact button:focus {
    outline: none;
}
.icon-1 img, .icon-2 img, .icon-4 img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.github-icon {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 3px;
    display: inline-block;
}
.icon-3 img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 8%;
    margin-top: 7%;
}
.icons-container {
    text-align: right;
}
.icons {
    text-align: left;
    display: inline-block;
}
.icon-1, .icon-2, .icon-3, .icon-4 {
    margin-top: 1vmin;
}
.contact p {
    font-size: 14px;
    display: inline;
    color: #A4A4A4;
    margin-left: 3vmin;
    position: relative;
    bottom: 1vh;
    cursor: pointer;
    transition-duration: 0.4s;
}
.contact p:hover {
    font-weight: bold;
}
.resume {
    width: 8%;
}
@media screen and (max-width: 600px) {
    .contact-grid {
        width: 250px;
    }
    .icon-1 img, .icon-2 img, .icon-4 img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .github-icon {
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 3px;
        display: inline-block;
    }
    .icon-3 img {
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-left: 8%;
        margin-top: 7%;
    }
    .contact p {
        font-size: 11px;
    }
    .items-container {
        text-align: center;
    }
    .contact h2 {
        text-align: center;
        font-size: 18px;
    }
    .contact h3 {
        text-align: center;
        font-size: 14px;
    }
    .contact-button-container {
        text-align: center;
        margin-bottom: 5vh;
    }
    .contact button {
        font-size: 12px;
        width: 75px;
        height: 20px;
    }
}
